import * as React from 'react';

/**
 * An <a> element with an empty href which otherwise behaves exactly like
 * a normal link, including the "pointer" cursor.
 */
export function A({
  children,
  ...htmlProps
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>): JSX.Element {
  return (
    <a href={void 0} style={{ cursor: 'pointer', ...htmlProps.style }} {...htmlProps}>
      {children}
    </a>
  );
}
