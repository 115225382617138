interface UrlResponse {
  url: string;
}

const functionUrls = {
  buy: process.env.GATSBY_AZURE_BUY_FUNCTION_URL || 'error_missing_buy_url',
  mySubscription: process.env.GATSBY_AZURE_MY_SUBSCRIPTION_FUNCTION_URL || 'error_missing_my_subscription_url',
  listSubscriptions: process.env.GATSBY_AZURE_HAS_SUBSCRIPTION_FUNCTION_URL || 'error_missing_has_subscription_url',
};

export async function getSubscriptionUrl(
  accessToken: string,
  subscriptionId: string
): Promise<string> {
  const res = await post<UrlResponse>(functionUrls.buy, accessToken, { subscriptionId });
  return res.url;
}

export async function getMySubscriptionUrl(accessToken: string): Promise<string> {
  const res = await get<UrlResponse>(functionUrls.mySubscription, accessToken);
  return res.url;
}

export async function hasSubscription(accessToken: string): Promise<boolean> {
  const result = await get<ListSubscriptionsResult>(functionUrls.listSubscriptions, accessToken);
  return result.hasSubscription
}

export async function listSubscriptions(accessToken: string): Promise<ListSubscriptionsResult> {
  return await get<ListSubscriptionsResult>(functionUrls.listSubscriptions, accessToken);
}

interface ListSubscriptionsResult {
  hasSubscription: boolean
  subscriptionType: 'free' | 'pro'
  licenseToken: string
}

async function get<TResponse>(url: string, accessToken: string): Promise<TResponse> {
  const res = await fetch(url, {
    method: 'GET',
    headers: authHeader(accessToken),
  });
  const json = await res.json();
  return json as TResponse;
}

async function post<TResponse>(url: string, accessToken: string, payload: any): Promise<TResponse> {
  const res = await fetch(url, {
    method: 'POST',
    headers: authHeader(accessToken),
    body: JSON.stringify(payload),
  });
  const json = await res.json();
  return json as TResponse;
}

function authHeader(accessToken: string): { authorization: string } {
  return {
    authorization: `Bearer ${accessToken}`,
  };
}
